import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import React, { useCallback, useEffect, useMemo } from 'react';

import type { Highlight, ReducedHighlight } from '../types';
import BaseContentFramePuppeteer from './BaseContentFramePuppeteer';
import { useHighlights } from './database/helperHooks';
import contentFrame, {
  portalGate as portalGateToContentFrame,
} from './portalGates/contentFrame/to/reactNativeWebview';
import type { WebContentFramePuppeteerProps } from './types/contentFramePuppeteerRelated';
import type { KnownHighlight } from './types/knownHighlights';
import cleanUpHtmlForHighlighting from './utils/cleanUpHtmlForHighlighting';

const areHighlightsEqual = (a: ReducedHighlight, b: KnownHighlight) => {
  const reducedHighlight: ReducedHighlight = {
    ...pick(a, ['id', 'location']),
    content: (a.content || '').trim(),
    html: cleanUpHtmlForHighlighting(a.html),
  };
  const reducedRenderedHighlight: ReducedHighlight = {
    ...pick(b, ['id', 'location']),
    content: (b.renderedData?.content || b.content || '').trim(),
    html: cleanUpHtmlForHighlighting(b.renderedData?.html ?? b.html),
  };

  return isEqual(reducedHighlight, reducedRenderedHighlight);
};

const defaultExport = React.memo(function WebContentFramePuppeteer(
  props: WebContentFramePuppeteerProps,
) {
  const {
    anchorScrollTarget,
    areDatabaseHooksEnabled = props.isActive,
    containerNodeSelector,
    contentContainer,
    docId,
    highlightIdToScrollTo: highlightIdToScrollToArgument,
    highlightLocationToScrollTo,
    setHighlightIdToScrollTo: setHighlightIdToScrollToArgument,
  } = props;

  const highlights = useHighlights({
    excludePDFHighlights: true,
    isEnabled: areDatabaseHooksEnabled,
    parentDocId: docId,
  });
  const highlightIdToScrollTo: Highlight['id'] | null = useMemo(
    () =>
      highlightIdToScrollToArgument ||
      (highlightLocationToScrollTo &&
        highlights.find((highlight) => highlight.location === highlightLocationToScrollTo)?.id) ||
      null,
    [highlightIdToScrollToArgument, highlightLocationToScrollTo, highlights],
  );
  const setHighlightIdToScrollTo = useMemo(
    () => setHighlightIdToScrollToArgument ?? contentFrame.setHighlightIdToScrollTo,
    [setHighlightIdToScrollToArgument],
  );
  useEffect(() => {
    if (!anchorScrollTarget) {
      return;
    }
    contentFrame.scrollToAnchor(anchorScrollTarget);
  }, [anchorScrollTarget]);

  useEffect(() => {
    if (!docId) {
      return;
    }

    setHighlightIdToScrollTo({
      containerNodeSelector,
      docId,
      id: highlightIdToScrollTo ?? null,
    });
    // docId is omitted to prevent over-calling
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentContainer, containerNodeSelector, highlightIdToScrollTo, setHighlightIdToScrollTo]);

  // There are no dependencies
  /* eslint-disable react-hooks/exhaustive-deps */
  const addContentFrameEventListener = useCallback(
    ((...args) => portalGateToContentFrame.on(...args)) as typeof portalGateToContentFrame.on,
    [],
  );
  const removeContentFrameEventListener = useCallback(
    ((...args) => portalGateToContentFrame.off(...args)) as typeof portalGateToContentFrame.off,
    [],
  );
  /* eslint-enable react-hooks/exhaustive-deps */

  const getLocationStringFromHighlight = useCallback((highlight: Highlight) => highlight.location, []);
  const preCreateOrResizeHighlight = useMemo(
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    () => props.preCreateOrResizeHighlight ?? (() => {}),
    [props.preCreateOrResizeHighlight],
  );

  const additionalPropsToPass = useMemo(
    () => ({
      activateHighlight: contentFrame.activateHighlight,
      addContentFrameEventListener,
      addHighlights: contentFrame.addHighlights,
      areHighlightsEqual,
      deactivateHighlight: contentFrame.deactivateHighlight,
      destroy: contentFrame.destroy,
      enlargeHighlights: contentFrame.enlargeHighlights,
      getKnownHighlights: contentFrame.getKnownHighlights,
      getCurrentSelectionInfo: contentFrame.getCurrentSelectionInfo,
      getHighlightIdsInSelection: contentFrame.getHighlightIdsInSelection,
      getFocusableElementIndexForSelection: contentFrame.getFocusableElementIndexForSelection,
      getHighlightIdsInSelector: contentFrame.getHighlightIdsInSelector,
      getLastRightClickedImageSelectionInfo: contentFrame.getLastRightClickedImageSelectionInfo,
      getLastRightClickedSelectionInfo: contentFrame.getLastRightClickedSelectionInfo,
      getLocationStringFromHighlight,
      getSelectionInfoForResizingHighlight: contentFrame.getSelectionInfoForResizingHighlight,
      getSelectionInfoFromSelector: contentFrame.getSelectionInfoFromSelector,
      init: contentFrame.init,
      preCreateOrResizeHighlight,
      removeContentFrameEventListener,
      removeHighlights: contentFrame.removeHighlights,
      excludePDFHighlights: true,
      updateIcons: contentFrame.updateIcons,
      scrollToAnchor: contentFrame.scrollToAnchor,
    }),
    [
      addContentFrameEventListener,
      getLocationStringFromHighlight,
      preCreateOrResizeHighlight,
      removeContentFrameEventListener,
    ],
  );

  return <BaseContentFramePuppeteer {...props} {...additionalPropsToPass} />;
});

// defaultExport.whyDidYouRender = {
//   trackHooks: true,
//   logOnDifferentValues: true,
// };

export default defaultExport;
