import React, { useCallback, useEffect, useMemo, useRef } from 'react';

import { globalState } from '../../../../shared/foreground/models';
import { ShortcutId } from '../../../../shared/types/keyboardShortcuts';
import { useKeyboardShortcutPreventDefault } from '../../hooks/useKeyboardShortcut';
import { useShortcutsMap } from '../../utils/shortcuts';
import documentContentStyles from '../DocumentTextContent.module.css';
import ChevronDownIcon from '../icons/ChevronDownIcon';
import ChevronUpIcon from '../icons/ChevronUpIcon';
import SearchNavIcon from '../icons/SearchNavIcon';
import StrokeCancelIcon from '../icons/StrokeCancelIcon';
import {
  clearFindInDocumentState,
  cycleNextMatch,
  cyclePreviousMatch,
  setQuery,
  toggleFindInDocumentBar,
} from './findInDocument';
import styles from './FindInDocumentBar.module.css';

export function FindInDocumentBar() {
  const findInDocumentState = globalState((state) => state.findInDocumentState);
  const query = useMemo(() => findInDocumentState?.query, [findInDocumentState]);
  const matchCount = useMemo(() => findInDocumentState?.matchCount ?? 0, [findInDocumentState]);
  const isVisible = useMemo(() => Boolean(findInDocumentState), [findInDocumentState]);
  const currentMatchIndex = useMemo(
    () => findInDocumentState?.currentMatchIndex ?? -1,
    [findInDocumentState],
  );
  const inputRef = useRef<HTMLInputElement>(null);
  const shortcutsMap = useShortcutsMap();

  const closeFindInDocumentBar = useCallback((userInteraction: string) => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
    clearFindInDocumentState(userInteraction);
  }, []);

  const handleInputKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Escape') {
        closeFindInDocumentBar('keypress');
      }
      if (event.key === 'Enter') {
        if (event.shiftKey) {
          cyclePreviousMatch();
        } else {
          cycleNextMatch();
        }
      }
    },
    [closeFindInDocumentBar],
  );

  useKeyboardShortcutPreventDefault(
    shortcutsMap[ShortcutId.FindInDocument],
    useCallback(() => {
      toggleFindInDocumentBar('keypress');
    }, []),
    {
      description: 'Find in Document',
      shouldBeIgnoredInFormTags: false,
    },
  );

  useKeyboardShortcutPreventDefault(
    shortcutsMap[ShortcutId.Esc],
    useCallback(() => {
      clearFindInDocumentState('keypress');
    }, []),
    { description: 'Find in Document' },
  );

  useEffect(() => {
    if (isVisible) {
      inputRef.current?.focus();
      return;
    }

    inputRef.current?.blur();
  }, [isVisible]);

  const inputMatchCount =
    query === '' || query === undefined
      ? ''
      : matchCount === 0
        ? 'No matches'
        : `${currentMatchIndex + 1}/${matchCount} matches`;

  return (
    <div
      className={`${documentContentStyles.documentSearchBarWrapper} ${isVisible ? styles.visible : styles.hidden}`}
    >
      <div className={documentContentStyles.searchInputWrapper}>
        <div className={documentContentStyles.searchIcon}>
          <SearchNavIcon />
        </div>
        <input
          ref={inputRef}
          aria-labelledby="document-search-label"
          autoComplete="off"
          autoCorrect="off"
          className={documentContentStyles.searchInput}
          placeholder="Find in document..."
          onKeyDown={handleInputKeyDown}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setQuery(event.target.value, 'keypress')
          }
        />
        <div className={documentContentStyles.searchInputInfoContainer}>
          {inputMatchCount}
          <button
            type="button"
            className={documentContentStyles.searchButton}
            onClick={() => {
              setQuery('', 'click');
              inputRef.current?.focus();
            }}
          >
            <StrokeCancelIcon text="Clear" className={documentContentStyles.searchButtonIcon} />
          </button>
        </div>
      </div>
      <div className={documentContentStyles.searchNavActions}>
        <button
          type="button"
          onClick={cyclePreviousMatch}
          className={documentContentStyles.searchButton}
        >
          <ChevronUpIcon text="Previous result" className={documentContentStyles.searchButtonIcon} />
        </button>
        <button type="button" onClick={cycleNextMatch} className={documentContentStyles.searchButton}>
          <ChevronDownIcon text="Next result" className={documentContentStyles.searchButtonIcon} />
        </button>
      </div>
      <div className={documentContentStyles.searchDoneAction}>
        <button
          type="button"
          onClick={() => {
            closeFindInDocumentBar('click');
          }}
          className={documentContentStyles.searchDoneButton}
        >
          <span>Done</span>
        </button>
      </div>
    </div>
  );
}
