import { CancelStateUpdate, updateState } from '../../../../shared/foreground/models';
import exceptionHandler from '../../../../shared/utils/exceptionHandler.platform';

export const setQuery = (query: string, userInteraction: string) => {
  updateState(
    (state) => {
      if (query === state.findInDocumentState?.query) {
        throw new CancelStateUpdate();
      }
      if (!state.findInDocumentState) {
        state.findInDocumentState = { query: '', matchCount: 0, currentMatchIndex: -1 };
      }
      state.findInDocumentState.query = query;
    },
    {
      eventName: 'find-in-document-set-query',
      userInteraction,
    },
  );
};

const cycleMatch = (forward: boolean) => {
  const eventName = forward ? 'find-in-document-go-to-next-match' : 'find-in-document-go-to-prev-match';
  updateState(
    (state) => {
      const findInDocumentState = state.findInDocumentState;

      if (!findInDocumentState) {
        exceptionHandler.captureException('Trying to cycle to match without FID state', {
          extra: {
            forward,
          },
        });
        return;
      }

      const currentMatchIndex = findInDocumentState.currentMatchIndex;
      const matchCount = findInDocumentState.matchCount;

      if (matchCount === 0) {
        return;
      }

      if (forward) {
        findInDocumentState.currentMatchIndex = (currentMatchIndex + 1) % matchCount;
      } else {
        findInDocumentState.currentMatchIndex = (currentMatchIndex - 1 + matchCount) % matchCount;
      }
    },
    {
      eventName,
      userInteraction: 'click',
    },
  );
};

export const cyclePreviousMatch = () => {
  cycleMatch(false);
};

export const cycleNextMatch = () => {
  cycleMatch(true);
};

export const clearFindInDocumentState = (userInteraction: string) => {
  updateState(
    (state) => {
      if (!state.findInDocumentState) {
        throw new CancelStateUpdate();
      }
      state.findInDocumentState = null;
    },
    {
      eventName: 'find-in-document-set-to-null',
      userInteraction,
    },
  );
};

export const toggleFindInDocumentBar = (userInteraction: string) => {
  updateState(
    (state) => {
      if (state.findInDocumentState) {
        state.findInDocumentState = null;
      } else {
        state.findInDocumentState = { query: '', matchCount: 0, currentMatchIndex: -1 };
      }
    },
    {
      eventName: 'find-in-document-bar-toggle-visible',
      userInteraction,
    },
  );
};
